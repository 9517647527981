<template>
  <div class="scroll">
    <!-- <NavbarOpen v-if="$route.name === 'open'" /> -->
    <Navbar v-if="$route.path !== '/articeListMsg'" />
    <!-- tab栏 -->
    <!-- 全局类容遮罩层 -->
    <ContentMask v-if="maskShow" />

    <transition name="fade" mode="out-in">
      <nuxt :key="key" />
    </transition>
    <!-- 联系我门 -->
    <!-- <Call
      v-show="$route.name !== 'login' && $route.name !== 'register'&& $route.name !== 'consultate'"/> -->
    <!-- 页面底部 -->
    <!-- 回到顶部 -->
    <BackTop  v-show="$route.name !== 'bookDetail'&&$route.path !== '/articeListMsg'" />
    <!-- 咨询浮框 -->
    <!-- <Ask v-if="key !== '/'" /> -->
    <Efooter v-show="$route.name !== 'login' && $route.name !== 'register' && $route.name !== 'bookDetail'&&$route.path !== '/articeListMsg'"  />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Navbar from '~/components/new-tab.vue'
import Efooter from '~/components/e-footer.vue'
import ContentMask from "../components/common/mask.vue";
import BackTop from "../components/common/backTop.vue";
import funny from '../utils/funny.js'

export default {
  components: {
    Navbar, Efooter, ContentMask, BackTop
  },
  data() {
    return {
      askActive: false,
      globalSt: 0,
    }
  },
  mounted() {
    this.$router.push = (e, blankFlag = true) => {
      if (blankFlag) {
        window.open(this.$router.resolve(e).href, '_blank')
      } else {
        location.href = this.$router.resolve(e).href
        // window.open(this.$router.resolve(e).href)
      }
    }
    if (process.env.environment === 'prod') {
      funny()
    }

    var ios = navigator.userAgent.indexOf('iphone');//判断是否为ios

    if (ios == -1) {
      //ios下运行
      var divEl = document.querySelector('.scroll')//你需要滑动的dom元素
      iosTrouchFn(divEl);
    }

    function iosTrouchFn(el) {
      //el需要滑动的元素
      el.addEventListener('touchmove', function (e) {
        e.isSCROLL = true;
      })
      document.body.addEventListener('touchmove', function (e) {
        if (!e.isSCROLL) {
          e.preventDefault(); //阻止默认事件(上下滑动)
        } else {
          //需要滑动的区域
          var top = el.scrollTop; //对象最顶端和窗口最顶端之间的距离
          var scrollH = el.scrollHeight; //含滚动内容的元素大小
          var offsetH = el.offsetHeight; //网页可见区域高
          var cScroll = top + offsetH; //当前滚动的距离

          //被滑动到最上方和最下方的时候
          if (top == 0) {
            top = 1; //0～1之间的小数会被当成0
          } else if (cScroll === scrollH) {
            el.scrollTop = top - 0.1;
          }
        }
      }, { passive: false }) //passive防止阻止默认事件不生效
    }

    // ---------------------神策配置开始----------------------'
    // 先引入sdk cdn地址，再初始化埋点配置
    // window.tsignData.init({
    //   mode: process.env.environment === 'prod' ? 'production' : process.env.environment === 'sml' ? 'sml' : 'development', // 生产环境需配置为production
    //   use_app_track: false
    // })
    // --------------------------神策配置结束------------------------------


  },
  computed: {
    ...mapGetters(['maskShow']),
    key() {
      return this.$route.fullPath
    }
  }
}
</script>


<style lang="scss">
body {
  // @media screen and (max-width: 767px) {
  //   .show {
  //     display: none;
  //   }
  //   min-width: 0;
  // }
  // min-width: 1300px;

  padding: 0 !important;
}
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  overflow-x: auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}
.scroll {
  position: relative;
}
</style>
