<template>
  <div>
    <div class="head_zwf" :class="$route.path !== '/' ? 'notHome' : ''" v-show="showSmallLogo"></div>
    <div class="mobileMenu" v-if="
      $route.path !== '/guide/filterPage' &&
      $route.path !== '/guide/constitutorFilter' &&
      $route.path !== '/guide/details' &&
      $route.path !== '/guide/mobileDeatils'
    ">
      <div class="tab-content container header-content">
        <div class="logo-area box" @click="gohome"></div>
        <div class="mobile-menu" @click="openMask"></div>
        <div v-if="isOpen" class="yxj-center mobile-menu-wrapper">
          <div class="mask" @click.self="isOpen = false"></div>
          <div class="yxj-center mobile-flex" @click.stop>
            <!-- tab按钮 -->
            <div class="tab_wrap mobile-review">
              <a @click="jumpTab('/')" :class="{ 'nuxt-link-exact-active': $route.path === '/' }">首页</a>
              <!-- <a href="https://cme.yxj.org.cn/" target="_blank">CME</a> -->
              <a @click="jumpTab('/newClass')" :class="{ 'nuxt-link-exact-active': $route.path === '/newClass' }">课程</a>
              <a @click="jumpTab('/live')" :class="{ 'nuxt-link-exact-active': $route.path === '/live' }">直播</a>
              <a @click="jumpTab('/survey')" :class="{ 'nuxt-link-exact-active': $route.path === '/survey' }">调研</a>
              <a @click="jumpTab('/brand')" :class="{ 'nuxt-link-exact-active': $route.path === '/brand' }">会议</a>
              <a @click="jumpTab('/guide')" :class="{
                'nuxt-link-exact-active':
                  $route.path === '/guide' ||
                  $route.path === '/guide/details',
              }">临床指南</a>
              <a @click="jumpTab('/ebooks')"
                :class="{ 'nuxt-link-exact-active': $route.path.includes('/ebooks') }">书库</a>
              <a style="font-family: arial" href="https://group.yxj.org.cn/" target="_blank">关于“医学界”</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <header class="header" :class="showSmallLogo && 'fixed'">
      <!-- tab栏 -->
      <div class="tab-content container">
        <div class="left">
          <!-- logo -->
          <a @click="jumpTab('/')" :class="{
            small_logo: true,
            show: showSmallLogo,
          }">
            <!-- <h1
              @click="gohome"
              class="logo_bg hand"
              :class="showSmallLogo && 'small_logo'"
            >
              <img src="../assets/images/yxj_home/logo.png" alt="" /> 
            </h1> -->
            <h1 @click="gohome" class="logo_bg hand small_logo">
              <img src="../assets/images/yxj_home/logo.png" alt="" />
            </h1>
          </a>
          <!-- tab按钮 -->
          <div class="tab_wrap">
            <a @click="jumpTab('/')" :class="{ 'nuxt-link-exact-active': $route.path === '/' }">首页</a>
            <!-- <a href="https://cme.yxj.org.cn/" rel="nofollow">CME</a> -->
            <a @click="jumpTab('/newClass')" :class="{ 'nuxt-link-exact-active': $route.path === '/newClass' }"
              rel="nofollow">课程</a>
            <a @click="jumpTab('/live')" :class="{ 'nuxt-link-exact-active': $route.path === '/live' }">直播</a>
            <a @click="jumpTab('/survey')" :class="{ 'nuxt-link-exact-active': $route.path === '/survey' }"
              rel="nofollow">调研</a>
            <a @click="jumpTab('/brand')" :class="{ 'nuxt-link-exact-active': $route.path === '/brand' }"
              rel="nofollow">会议</a>
            <a @click="jumpTab('/guide')" class="free-link" :class="{
              'nuxt-link-exact-active':
                $route.path === '/guide' || $route.path === '/guide/details',
            }">临床指南</a>
            <!-- <a @click="jumpTab('/ebooks')" :class="{ 'nuxt-link-exact-active': $route.path.includes('/ebooks') }"
              rel="nofollow">书库</a> -->
            <a style="font-family: arial" href="https://group.yxj.org.cn/" rel="nofollow">关于“医学界”</a>
          </div>
        </div>
        <div class="right">
          <!-- 搜索 -->
          <div class="search-content" v-show="$route.path !== '/search'">
            <!-- 搜索输入框 -->
            <!-- <el-input
              ref="searchRef"
              @blur="inputBlur(true)"
              @focus="inputBlur(false)"
              class="search_input"
              :class="(search_input_show || showSmallLogo) && 'focus'"
              placeholder="搜索"
              v-model.trim="keyword"
              @keyup.enter.native="goSearchPage"
            >
            </el-input> -->
            <el-input ref="searchRef" class="search_input focus" placeholder="搜索" v-model.trim="keyword"
              @keyup.enter.native="goSearchPage">
            </el-input>
            <div class="center_line"></div>
            <i @click="goSearchPage" slot="suffix" class="search hand"></i>
          </div>

          <!-- <div class="login_wrap" :class="showSmallLogo && 'small_logo'">
            <button @click="$refs.login.show()" class="login" v-if="!userInfo">
              登录 | 注册
            </button>
            <nuxt-link to="/self" class="user_name" v-else
              ><span>{{ userInfo && userInfo.nickName }}</span>
              <div class="img">
                <img v-lazy="userInfo && userInfo.headUrl" alt="" /></div
            ></nuxt-link>
            <a href="http://product.yxj.org.cn/#/" target="_blank">
              <img
                class="download_enter"
                src="../assets/images/yxj_home/download-enter.png"
                alt=""
              />
            </a>
          </div> -->
          <div class="login_wrap small_logo">
            <button @click="$refs.login.show()" class="login" v-if="!userInfo || !userInfo.uid">
              登录
            </button>
            <!-- <nuxt-link to="/self" class="user_name" v-else
              ><span>{{ userInfo && userInfo.nickName }}</span>
              <div class="img">
                <img v-lazy="userInfo && userInfo.headUrl" alt="" /></div
            ></nuxt-link> -->
            <div @click="jumpTab('/self')" class="user_name selfName" v-else>
              <!-- <span>{{ userInfo && userInfo.nickName }}</span> -->
              <div class="img">
                <img v-lazy="userInfo && userInfo.headUrl" alt="" />
              </div>
              <div class="longName">
                <div>
                  <a @click="jumpTab('/self')">{{
                    userInfo && userInfo.nickName
                  }}</a>
                </div>
              </div>
            </div>
            <div style="margin-left: 20px" class="real">
              <div class="login">移动端 <i class="el-icon-arrow-down"></i></div>
              <div class="device_download">
                <div>
                  <a href="http://product.yxj.org.cn" target="_blank">医学界医生站</a>
                </div>
                <div>
                  <a style="line-height: 20px" href="http://product.yxj.org.cn/#/tool" target="_blank">临床决策助手</a>
                </div>
              </div>
            </div>
            <!-- <a href="http://product.yxj.org.cn/#/" target="_blank">
              <img
                class="download_enter"
                src="../assets/images/yxj_home/download-enter.png"
                alt=""
              />
            </a> -->
          </div>
        </div>
      </div>
      <div class="real_in">
        <div class="login"><i class="custom-icon"></i> 中文 <i class="el-icon-arrow-down"></i></div>
        <div class="device_download">
          <div style=" color: #d70c19; background-color: #fdf3f3;">
            中文
          </div>
          <div @click="visable=true">
            EN
          </div>
        </div>
      </div>
      <!-- 登录弹窗 -->
      <Login @loginSuccess="loginSuccess" ref="login" />
      <!--  -->
      <div class="modal_box" v-if="visable">
      <div class="modal_box_content">
        <div class="content_title">您即将进入国际版网站，内容和账号都会发生变化，您确定要进入吗？</div>
        <div class="content_btn">
          <div class="btn_item" @click="handelCancel">取消</div>
          <div class="btn_item btn_item_ok" @click="handelConfirm">确认</div>
        </div>
      </div>
    </div>
    </header>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import Login from "../components/user/login.vue";
import Vue from 'vue'
import cookies from 'js-cookie'

import { connectionSocket } from '../utils/sockjs'
export default {
  components: { Login },
  // head() {
  //   return {
  //     title: this.seo.seoTitle || '',
  //     meta: [
  //       { hid: 'keyword', name: 'keyword', content: this.seo.seoKey || '' },
  //       { hid: 'description', name: 'description', content: this.seo.seoDesc || '' }
  //     ]
  //   }
  // },
  created () {
  },
  data () {
    return {
      isOpen: false,
      scrollFlag: false,
      redshow: 0,
      userInfo: null,
      current_menu: '', // 这里要判断查询参数是否有departmentId，然后再去遍历，默认才给今日医学
      keyword: '',
      search_input_show: false,
      channelList: [ // 频道列表模拟数据
      ],
      channelList1: [],//频道列表原始数据
      activeI: 0,
      lineLeft: 0,
      lineWidth: 0,
      lineOpacity: 0,
      selectShow: false,
      eventList: [],
      visable:false
    }
  },
  computed: {
    ...mapGetters(['maskShow', 'department']),
    showSmallLogo () {
      return this.scrollFlag || this.$route.path !== '/'
    },
  },
  mounted () {
    // this.initAnimate()
    // setTimeout(() => {
    //   this.showSmallLogo = true
    // }, 5000);
    connectionSocket()
    if (this.$route.path === '/brand') {
      this.redshow = 6
    }
    // 外部登录回调方法更新组件数据
    Vue.prototype.userChange = this.userChange
    Vue.prototype.loginChange = this.loginChange
    // 外部通过科室id跳转科室首页方法
    Vue.prototype.outsideJumpToDepartmentIndexByDeptId = this.outsideJumpToDepartmentIndexByDeptId

    // this.queryWebNewsDepartmentList()
    // console.log('this.$route-------------', this.$route)
    // 监听路由确定tab指向
    // let index = this.filterTabIndex(this.$route)
    this.getUserInfo()
    // setTimeout(() => {
    //   let pbNavOffsetLeft = 0
    //   // let pbNavOffsetLeft = this.$refs.tabBox.offsetLeft
    //   let $width = this.$refs.tabBox.querySelectorAll('button')[index].clientWidth
    //   let dis = 20
    //   if (window.innerWidth <= 1680) dis = 18
    //   let $left = this.$refs.tabBox.querySelectorAll('button')[index].offsetLeft - pbNavOffsetLeft - 1 + dis

    //   $width = $width - dis * 2
    //   if (window.innerWidth <= 1440) {
    //     $left -= 4
    //     $width += 8
    //   }
    //   this.lineOpacity = 1
    //   this.lineLeft = $left + Number((this.$refs.tabBox.querySelectorAll('button')[index].clientWidth - 62) / 2)
    //   this.lineWidth = 24
    // })
  },
  methods: {
    ...mapMutations(['traggleMask', 'closeTypeList', 'closeMask', 'setepartment']),
    handelCancel(){
      this.visable = false
    },
    handelConfirm(){
      this.visable = false
      window.location.href = 'https://medj.cn/'
    },
    openMask () {
      console.log('哈哈哈');
      this.isOpen = !this.isOpen
    },
    jumpTab (path) {
      console.log(123456)
      if (path === this.$route.path) {
        location.reload()
        return
      }
      this.$router.push(path, false)
    },
    inputBlur (f) {
      if (f && !this.showSmallLogo) {
        this.search_input_show = false
      } else {
        this.search_input_show = true
      }
    },
    initAnimate () {
      console.log('获取当前路由参数-----', this.$route)
      window.addEventListener('scroll', (e) => {
        console.log(e, document.documentElement.scrollTop)
        this.scrollFlag = document.documentElement.scrollTop > 116
      })

      this.scrollFlag = document.documentElement.scrollTop > 116
    },
    outsideJumpToDepartmentIndexByDeptId (id) {
      // console.log(id)
      // var item = this.channelList1.find(ele => ele.dptid === id)
      // console.log(item)
      this.changeChannle(this.channelList1.find(ele => ele.dptid === id))
    },
    gohome () {
      this.setepartment(null)
    },
    userChange () {
      // console.log('userChangeuserChangeuserChangeuserChangeuserChangeuserChange')
      this.getUserInfo()
    },
    loginSuccess () {
      this.getUserInfo()

      this.eventList.forEach(ele => {
        ele('loginSuccess')
      });
    },
    loginChange (cb) {
      this.eventList.push(cb)
    },
    async getUserInfo () {
      var token = cookies.get('token')
      // console.log('判断是否登录-------------', token)
      if (!token) {
        this.userInfo = null
        if (this.$route.path.split('/')[1] === 'self') {
          this.$router.push('/')
        }
        this.$store.commit('isLogin', false)
        return
      }
      try {
        var res = await this.$axios(
          {
            method: 'post',
            url: process.env.baseUrl + 'ysz-auth/auth/info',
            data: {},
            headers: {
              token: token
            }
          })
        // console.log('--------------------------', res)
        if (res && res.data.code === 200) {
          this.userInfo = res.data.body
          this.userInfo && this.userInfo.uid && localStorage.setItem('userInfo', JSON.stringify(res.data.body))
          this.eventList.forEach(ele => {
            ele('userInfo')
          });
          if (this.userInfo.deptId) {
            // console.log(11111111111111111111)
            var item = this.channelList1.find(item => {
              // console.log(item)
              return item.dptid == this.userInfo.deptId
            })
            // console.log(item)
            if (item) {
              this.setepartment(item)
            }
          }
          this.$store.commit('isLogin', true)
        } else {
          // console.log('获取用户信息失败，删除缓存重新登录')
          localStorage.clear()
          cookies.remove('token')
          if (this.$route.path.split('/')[1] === 'self') {
            this.$router.push('/')
          }
          this.$store.commit('isLogin', false)
        }
      } catch (error) {
        this.$store.commit('isLogin', false)
        localStorage.clear()
        cookies.remove('token')
        console.log(1111111, error)
      }
    },
    handleCommand (command) {
      this.current_menu = command
    },
    // 获取所有科室列表
    queryWebNewsDepartmentList () {
      this.$axios.$post('/ysz-content/web/home/news/queryDepartmentList', {}).then(({ body: res = [] }) => {
        // console.log(res, '所有科室');
        // 处理成四列
        let index = 0
        var list = []
        // console.log(1111111111111, res)
        res.forEach(ele => {
          // console.log(ele)
          if (!ele) {
            list.push({ dptid: 0, name: '今日医学界' })
          } else {
            list.push(ele)
          }
        });
        res = list
        this.channelList1 = res
        let clumn_num = Math.ceil(res.length / 4)
        for (let i = 0; i < res.length; i += clumn_num) {
          this.channelList[index] = res.slice(i, i + clumn_num)
          index++
        }
        // console.log(this.channelList, '++++++++++++++');
        // this.channelList = res
        // console.log('this.channelList------------------------', this.channelList, res)

        // console.log(res)
        this.setepartment(res.find(item => {
          // console.log(item)
          return item.dptid == (this.$route.query.deptID || 0)
        }))
      })
        .catch(err => {
          console.log(err);
        })
    },
    /**
     * 切换频道
     * item: 频道
     */
    changeChannle (item) {
      // console.log(item)
      this.current_menu = item.name
      // localStorage.setItem('current_menu', JSON.stringify(item))
      this.closeTypeList()
      this.closeMask()
      this.setepartment(item)
      // console.log(1111111111111, item)
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery.deptID = item.dptid
      newQuery.change = 1
      // console.log(11111111111, newQuery)
      this.$router.push({
        // path: this.$route.path,
        path: '/',
        query: newQuery,
      })
      // console.log(this.$route)
    },
    // 点击搜索按钮
    showSearchInput () {
      // 隐藏当前图标，显示输入框
      setTimeout(() => {
        // console.log(this.$refs.searchRef, 'this.$refs');
        this.$refs.searchRef.focus()
      }, 4);
      this.search_input_show = true
    },
    // 跳转搜索路由
    goSearchPage () {
      console.log(123456)
      this.$router.push('/search?kw=' + this.keyword)
      this.keyword = ""
      this.search_input_show = false
    },
    targetClick (index, dom) {
      this.activeI = index
      if (dom) {
        // console.log(dom.offsetLeft, '====================');
        // let pbNavOffsetLeft = this.$refs.tabBox.offsetLeft
        let pbNavOffsetLeft = 0
        let $width = dom.clientWidth
        let dis = 20
        if (window.innerWidth <= 1680) dis = 18
        let $left = dom.offsetLeft - pbNavOffsetLeft - 1 + dis

        $width = $width - dis * 2
        if (window.innerWidth <= 1440) {
          $left -= 4
          $width += 8
        }
        this.lineOpacity = 1
        this.lineLeft = $left + Number((dom.clientWidth - 62) / 2)
        this.lineWidth = 24
      }
    },
    toOldPageFun (url) {
      // window.location.href = process.env.oldBaseUrl + url
      window.open(process.env.oldBaseUrl + url)
    },
    // 展示全部频道
    showAllType () {
      this.traggleMask(!this.maskShow)
      this.typeFlag = !this.maskShow
    },
  },
  watch: {
    $route (newVal, oldVal) {
      if (this.$route.path === '/live') {
        this.redshow = 3
      }
      if (this.$route.path === '/class') {
        this.redshow = 4
      }
      if (this.$route.path === '/brand') {
        this.redshow = 6
      }
      if (this.$route.path === '/survey') {
        this.redshow = 5
      }
      if (this.$route.path === '/') {
        this.redshow = 0
      }
      // this.checkTabByPath(newVal)
    },
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px) {
  .mobileMenu {
    display: none;
  }

  .head_zwf {
    height: 180px;

    &.notHome {
      height: 64px;
    }
  }

  .user_name {
    display: flex;
    align-items: center;
    padding-left: 15px;

    // width: 300px;
    // justify-content: flex-end;
    // margin-left: 1480px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap !important;
      max-width: 100px;
      display: block;
    }

    .img {
      width: 24px;
      padding-left: 4px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
  }

  .header {
    position: relative;
    left: 0;
    top: 0;
    z-index: 300;
    width: 100%;
    // height: 149px;
    box-shadow: 0px 4px 20px 0px #f0f0f0;
    background-color: #fff;

    &.fixed {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 300;
    }

    .real_in {
    &:hover {
      .device_download {
        display: block !important;
      }
    }
  }

  .real_in {
    position: absolute;
    right: 30px;
    top: 22px;
    font-size: 15px;
    display: flex;
    align-items: center;
    color:#333;
    font-family: PingFangSC, PingFangSC-Regular, Noto Sans SC;
    cursor: pointer;
    .custom-icon{
      background: url('https://studioyszimg.yxj.org.cn/enzh.png') no-repeat;
      width: 14px; /* 或者您需要的尺寸 */
      height: 14px; /* 或者您需要的尺寸 */
      display: inline-block;
      background-size:100% ;
      position: absolute;
      left: -19px;
      top: 3px;
    }
    .device_download {
      display: none;
      position: absolute;
      top: 18px;
      right: 5px;
      width: 100px;
      height: 81px;
      text-align: center;
      background: #fbfbfb;
      box-shadow: 0px 2px 10px 0px rgba(196, 196, 196, 0.5);
      opacity: 0.94;
      border-radius: 5px;
      padding-top: 10px;
      &:hover {
        display: block !important;
      }

      // padding: 20px 0;
      div {
        font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          cursor: pointer;
          line-height: 30px;
          height: 30px;
          &:hover {
            color: #d70c19;
            background-color: #fdf3f3;
          }
          a:hover{
            color: #d70c19;
          }
      }
    }
  }

    h4 {
      background: #fbfbfb;
      height: 32px; // 这里有1px是下border
      border-bottom: 1px solid rgba(74, 74, 74, 0.07);

      .main {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          justify-content: flex-start;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;

          .device_select {
            display: none;
            width: 135px;
            height: 93px;
            background: #fbfbfb;
            box-shadow: 0px 2px 10px 0px rgba(196, 196, 196, 0.5);
            opacity: 0.94;
            z-index: 999;
            position: relative;
            left: -20px;
            padding: 20px 10px;

            &:hover {
              display: block !important;
            }

            div {
              text-align: center;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #222222;
              line-height: 22px;

              a {
                &:hover {
                  color: #d70c19;
                }
              }
            }
          }

          .device {
            width: 118px;

            &:hover {
              .device_select {
                display: block !important;
              }
            }
          }
        }
      }

      .login {
        font-size: 14px;
        font-weight: 400;
        color: #333333;

        &:hover {
          color: #d70c19;
        }
      }
    }

    .logo_wrap {

      // overflow: hidden;
      img {
        width: 112px;
        margin: 22px auto 8px;
      }
    }

    .tab-content {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;

      .left {
        width: 913px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.2s linear;

        .logo_bg {
          width: 0;
          margin-right: 0;
          transition: width 0.2s linear;
          opacity: 0;

          &.small_logo {
            width: 90px;
            margin-right: 20px;
            animation: logoFade 0.2s linear 0.2s forwards;
          }

          @keyframes logoFade {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }

          // margin-right: 18px;
        }
      }

      .tab_wrap {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      a {
        // flex:1;
        transition: all 0.2s linear;
        text-align: right;
        line-height: 48px;
        cursor: pointer;

        &.none {
          flex: 0;
        }

        &:hover {
          color: #d70c19;
        }

        &.nuxt-link-exact-active {
          color: #d70c19;
          position: relative;
          font-weight: 700;

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            // display: block;
            width: 36px;
            height: 3px;
            background: #d93639;
            border-radius: 2px;
          }
        }

        &.small_logo.nuxt-link-exact-active:after {
          display: none;
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 60px;
      margin-right: 113px;
      .login_wrap {
        width: 0;
        // margin: 0;
        transition: all 0.2s linear;
        text-align: right;
        opacity: 0;
        // overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        .download_enter {
          width: 10px;
          margin-left: 10px;
          // height:auto;
          // padding: 0 10px;
        }

        &.small_logo {
          width: 150px;
          // margin-left: 80px;
          opacity: 1;
        }
      }

      .login {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        // margin-left: 80px;
        white-space: nowrap;

        &:hover {
          color: #d70c19;
        }
      }
    }

    .search-content {
      // flex: 2;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border: 1px solid #dfdfdf;
      overflow: hidden;

      .center_line {
        width: 1px;
        height: 21px;
        border-right: 1px solid #dfdfdf;
      }

      /deep/.search_input {
        width: 76px;
        height: 40px;
        // background: #ffffff;
        border: 0;
        transition: width 0.2s linear;

        &.focus {
          width: 206px;
        }

        .el-input__inner {
          // width: 298px;
          height: 40px;
          background: transparent;
          border-radius: 0;
          border: 0;

          &:focus {
            width: 100px;
          }
        }

        .el-input__prefix {
          margin-left: 9px;
          display: flex;
          align-items: center;
        }
      }

      // .search_icon_content {
      //   width: 47px;
      //   height: 33px;
      //   background: #f5f5f5;
      //   border-radius: 17px;
      //   cursor: pointer;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
      .search {
        display: block;
        width: 64px;
        height: 40px;
        // background-color: #d70c19;
        color: #d70c19;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
          display: block;
          content: "";
          width: 21px;
          height: 21px;
          background-image: url("../assets/images/yxj_home/sousuo-2@2x.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .divider {
        width: 1px;
        height: 14px;
        background: #4a4a4a;
        line-height: 33px;
        margin: 0 8px;
      }

      .sign {
        height: inherit;
        line-height: 33px;
        font-size: 14px;

        font-weight: 400;
        color: #4a4a4a;
        margin-right: 5px;
      }
    }
  }

  /deep/ li {
    clear: none;
  }

  .header-top {
    position: relative;

    .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      font-size: 20px;
    }
  }

  .real {
    &:hover {
      .device_download {
        display: block !important;
      }
    }
  }

  .real {
    .device_download {
      display: none;
      position: absolute;
      top: 20px;
      // right: 50px;
      width: 125px;
      height: 93px;
      text-align: center;
      background: #fbfbfb;
      box-shadow: 0px 2px 10px 0px rgba(196, 196, 196, 0.5);
      opacity: 0.94;

      &:hover {
        display: block !important;
      }

      // padding: 20px 0;
      div {
        a {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;

          &:hover {
            color: #d70c19;
          }
        }
      }
    }
  }

  .selfName {
    &:hover {
      .longName {
        display: block !important;
      }
    }
  }

  .selfName {
    cursor: pointer;

    .longName {
      display: none;
      position: absolute;
      top: 20px;
      width: 125px;
      text-align: center;
      background: #fbfbfb;
      box-shadow: 0px 2px 10px 0px rgba(196, 196, 196, 0.5);
      opacity: 0.94;

      &:hover {
        display: block !important;
      }

      div {
        a {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;

          &:hover {
            color: #d70c19;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header {
    display: none;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: #ffffff;
    transition: 0.3s;

    .header-content {
      position: relative;
      display: block;
      text-align: center;
      height: 48px;
      line-height: 48px;

      .mobile-menu {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 24px;
        height: 24px;
        background-image: url("http://studioyszimg.yxj.org.cn/1690257212009.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
      }

      .logo-area {
        position: absolute;
        top: 14px;
        left: 16px;
        // margin-top: 10px;
        width: 43px;
        height: 20px;
        line-height: 48px;
        background: url("http://studioyszimg.yxj.org.cn/1690264695592.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .mobile-menu-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 99;
        transition: 0.5s;

        .mask {
          position: fixed;
          top: 48px;
          height: 100vh;
          width: 100vw;
          background: rgba(0, 0, 0, 0.32);
          z-index: 50;
          transition: 0.5s;
        }

        .mobile-flex {
          position: relative;
          display: block;
          margin-top: 48px;
          width: 136px;
          height: 100vh;
          background-color: #ffffff;
          transition: 0.5s;
          box-sizing: border-box;
          z-index: 60;
        }
      }
    }

    .mobile-review {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;
      padding-top: 53px;

      // background-color: #D7000F;
      a {
        font-size: 16px;
        transition: all 0.2s linear;
        line-height: 22px;
        color: #333333;
        cursor: pointer;
        text-align: center;
        margin-bottom: 36px;
        font-weight: normal;
        font-family: PingFangSC-Medium;

        &.none {
          flex: 0;
        }

        &:hover {
          color: #d93639;
        }

        &.nuxt-link-exact-active {
          color: #d93639;
          position: relative;
          font-weight: 700;

          &:after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            left: 0;
            // display: block;
            width: 4px;
            height: 23px;
            background: #d93639;
            border-radius: 2px;
          }
        }

        &.small_logo.nuxt-link-exact-active:after {
          display: none;
        }
      }
    }
  }
}

.free-link {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 34px;
    height: 16px;
    background: url("../assets/img/tab_free.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -34px;
    top: 0;
  }
}
.modal_box{
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_box_content{
    width: 500px;
    height: 200px;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 30px;
    .content_title{
      color: #333333;
      font-size: 16px;
      line-height: 26px;
    }
    .content_btn{
      width: 60%;
      margin-left: 20%;
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      .btn_item{
        width: 105px;
        height: 36px;
        border-radius: 5px;
        border: 1px solid #E5E5E5;
        background: #FFFFFF;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }
      .btn_item_ok{
        background: #D70C18;
        border: 1px solid #D70C18;
        color: #FFFFFF;
      }
    }
  }
}
</style>